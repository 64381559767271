import React, { Component } from "react";
import { shape, arrayOf, string, func, bool } from "prop-types";

import { RadioInputGroup, RadioInput } from "@instructure/ui-forms";
import { Modal } from "@instructure/ui-overlays";
import { Heading } from "@instructure/ui-elements";
import { Button, CloseButton } from "@instructure/ui-buttons";

export default class RnPActivityModal extends Component {
  static propTypes = {
    questionsToShow: arrayOf(
      shape({
        questionId: string,
        questionText: string,
        options: shape({
          answerId: string,
          answerText: string
        })
      })
    ),
    header: string,
    handleSubmit: func,
    handleModalClose: func,
    isOpen: bool
  };

  constructor(props) {
    super(props);

    const initialState = {};

    props.questionsToShow.forEach(q => (initialState[q.questionId] = null));

    this.state = initialState;
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit(this.state);
  };

  render() {
    const hasResponses = Object.keys(this.state).every(x => !!this.state[x]);
    return (
      <Modal
        as="form"
        open={this.props.isOpen}
        onDismiss={this.props.handleModalClose}
        onSubmit={this.handleSubmit}
        label={`Modal Dialog: ${this.props.header}`}
        shouldCloseOnDocumentClick
      >
        <Modal.Header>
          <CloseButton
            placement="end"
            offset="medium"
            variant="icon"
            onClick={this.props.handleModalClose}
          >
            Close
          </CloseButton>
          <Heading>{this.props.header}</Heading>
        </Modal.Header>
        <Modal.Body>
          {this.props.questionsToShow &&
            this.props.questionsToShow.map(question => (
              <div style={{ marginBottom: "1em" }} key={question.questionId}>
                <RadioInputGroup
                  name={question.questionId}
                  onChange={event => {
                    this.setState({
                      [question.questionId]: event.currentTarget.value
                    });
                  }}
                  value={this.state[question.questionId]}
                  description={question.questionText}
                >
                  {question.options.map(option => (
                    <RadioInput
                      key={option.answerId}
                      label={option.answerText}
                      value={option.answerId}
                    />
                  ))}
                </RadioInputGroup>
              </div>
            ))}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.props.handleModalClose}>Close</Button>&nbsp;
          <Button variant="success" type="submit" disabled={!hasResponses}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
