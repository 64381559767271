import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Image from "../../../components/image";
import QuestionHeader from "../../../components/question-header";
import styles from "./reasonable-and-prudent-activity.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";
import RnPActivityModal from "../../../components/rnp-activity-modal";

const STANDARDS = [
  {
    letter: "A",
    words: "Age",
    questions: [
      {
        questionId: "A-1",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "A-1-1",
            answerText: "Ana is old enough to go with the neighbor family."
          },
          {
            answerId: "A-1-2",
            answerText:
              "Ana is too young to go since she might be supervised by an older kid."
          }
        ]
      },
      {
        questionId: "A-2",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "A-2-1",
            answerText:
              "Ana plays at the neighbor’s house so she is used to being away from home."
          },
          {
            answerId: "A-2-2",
            answerText:
              "Playing next door is different than going to a big waterpark – she might get overwhelmed."
          }
        ]
      }
    ]
  },
  {
    letter: "R",
    words: "Risk",
    questions: [
      {
        questionId: "R-1",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "R-1-1",
            answerText:
              "There is some physical risk but she can wear her water wings."
          },
          {
            answerId: "R-1-2",
            answerText:
              "There is too much physical risk because she doesn’t swim yet."
          }
        ]
      },
      {
        questionId: "R-2",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "R-2-1",
            answerText:
              "Ana will have enough supervision: neighbor, older kids, lifeguards."
          },
          {
            answerId: "R-2-2",
            answerText:
              "There won’t be enough adult supervision for a child who doesn’t swim."
          }
        ]
      }
    ]
  },
  {
    letter: "B",
    words: "Behavior",
    questions: [
      {
        questionId: "B-1",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "B-1-1",
            answerText: "Ana usually plays with these kids with no issues."
          },
          {
            answerId: "B-1-2",
            answerText:
              "Ana had a meltdown recently – what if she gets overstimulated and has another one?"
          }
        ]
      },
      {
        questionId: "B-2",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "B-2-1",
            answerText:
              "A half day is too long – she got tired of the pool after an hour."
          },
          {
            answerId: "B-2-2",
            answerText:
              "There’s more to do at a waterpark than a pool, so she’ll stay engaged."
          }
        ]
      }
    ]
  },
  {
    letter: "E",
    words: "Emotional Development",
    questions: [
      {
        questionId: "E-1",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "E-1-1",
            answerText: "It’s a great chance for Ana to try a fun new activity."
          },
          {
            answerId: "E-1-2",
            answerText:
              "It’s better to do it with a parent first so they can monitor her reaction."
          }
        ]
      }
    ]
  },
  {
    letter: "F",
    words: "Family",
    questions: [
      {
        questionId: "F-1",
        questionText: "Which idea best describes your feeling?",
        options: [
          {
            answerId: "F-1-1",
            answerText:
              "It lets her experience a family-type activity and it’s a good way to bond with others."
          },
          {
            answerId: "F-1-2",
            answerText:
              "It’s better to do a brand new activity with you and your family."
          }
        ]
      }
    ]
  },
  {
    letter: "I",
    words: "Best Interest",
    questions: [
      {
        questionId: "I-1",
        questionText:
          "The last item is an overall assessment. Review your previous answers, then make your final decision.",
        options: [
          {
            answerId: "I-1-1",
            answerText: "Yes, the waterpark is in her best interest."
          },
          {
            answerId: "I-1-2",
            answerText: "No, the waterpark is not in her best interest."
          }
        ]
      }
    ]
  }
];

export class RnPActivity extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  state = {
    hasSubmitted: false,
    openItems: [],
    answeredLetters: [],
    modalOpen: false,
    modalContentLetter: "A",
    modalSelectedResponses: {
      A: [],
      R: [],
      B: [],
      E: [],
      F: [],
      I: []
    }
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(5);
  }

  handleModalSubmit = answers => {
    const newAnsweredLetters = this.state.answeredLetters.concat([
      this.state.modalContentLetter
    ]);

    const newModalSelectedAnswers = { ...this.state.modalSelectedResponses };
    newModalSelectedAnswers[this.state.modalContentLetter] = Object.values(
      answers
    );

    this.setState({
      answeredLetters: newAnsweredLetters,
      modalSelectedResponses: newModalSelectedAnswers,
      modalOpen: false
    });
  };

  handleActivate = id => {
    if (this.state.openItems.includes(id)) {
      this.setState({
        openItems: this.state.openItems.filter(x => x !== id)
      });
    } else {
      this.setState({
        openItems: this.state.openItems.concat(id)
      });
    }
  };

  onSubmit = () => {
    if (!this.state.hasSubmitted) {
      this.setState({ hasSubmitted: true });
    } else {
      navigate("/modules/normalcy/rnp-unsure");
    }
  };

  goBack = () => {
    if (this.state.hasSubmitted) {
      this.setState({ hasSubmitted: false });
    } else {
      navigate("/modules/normalcy/reasonable-prudent-parenting-standards");
    }
  };

  openModal = letter =>
    this.setState({
      modalOpen: true,
      modalContentLetter: letter
    });

  handleModalClose = () => this.setState({ modalOpen: false });

  renderScenario() {
    return (
      <>
        <div className={styles.scenarioImage}>
          <Image alt="water park" filename="water-park.jpg" />
        </div>
        <div className={styles.scenarioText}>
          <p>
            You’ve been fostering 5-yr-old Ana for three months. She enjoys
            playing with the neighbor kids next door – they love to run through
            the sprinkler in the backyard. You took Ana to the pool once; she
            wore water-wings and played on the pool steps and was ready to leave
            after an hour. Ana is typical in many ways although sometimes she
            has meltdowns – she had one recently while playing with a group of
            kids at the park.
          </p>
          <p>
            The neighbor just invited Ana to go to the waterslide park with her
            four kids for the afternoon. Although the neighbor will be the only
            adult, her older kids will help out with the younger kids if they
            need it.
          </p>
        </div>
      </>
    );
  }

  render() {
    let standardToUse = {};
    if (this.state.modalContentLetter) {
      standardToUse = STANDARDS.find(
        x => x.letter === this.state.modalContentLetter
      );
    }

    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - Reasonable & Prudent Parenting Standards"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          {this.state.hasSubmitted ? (
            <>
              <div className={styles.feedback}>
                <p>
                  Was it easy to make a decision, or did you find yourself
                  hesitating on some items?
                </p>
                <p>
                  Answers aren’t always clear-cut. Different parents could
                  arrive at different conclusions. In the previous scenario, a
                  parent could make a compelling case for allowing Ana to go, or
                  for keeping her home. There isn't always one correct answer;
                  it depends on how the parent assesses the situation and the
                  child.
                </p>

                <p>
                  The key is to thoughtfully apply the criteria to your
                  individual child and make your best judgment. As the foster
                  parent, you <u>often have</u> the most information to decide
                  if an activity is right for the child in your care.
                </p>
              </div>
              <div className={styles.backButtonArea}>
                <Button
                  onClick={this.goBack}
                  variant="success"
                  icon={IconArrowOpenStart}
                />
              </div>
              <div className={styles.forwardButtonArea}>
                <Button
                  onClick={this.onSubmit}
                  variant="success"
                  icon={IconArrowOpenEnd}
                  disabled={this.state.answeredLetters.length < 6}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.questionTextArea}>
                <QuestionHeader
                  icon={
                    <span
                      className={styles.emoji}
                      role="img"
                      aria-label="Thinking Face"
                    >
                      🤔
                    </span>
                  }
                >
                  To help you apply the Reasonable & Prudent Parenting standard
                  (ARBEFI), here are some questions to consider. Click each
                  letter and answer the questions.
                </QuestionHeader>
              </div>
              <div className={styles.activityArea}>
                <div className={styles.activityButtons}>
                  {STANDARDS.map((standard, index) => {
                    const answersText = this.state.modalSelectedResponses[
                      standard.letter
                    ].map(r => {
                      return (
                        <li key={r}>
                          {
                            STANDARDS.find(s => s.letter === standard.letter)
                              .questions.find(q => r.includes(q.questionId))
                              .options.find(o => o.answerId === r).answerText
                          }
                        </li>
                      );
                    });

                    return (
                      <>
                        <Button
                          key={standard.letter}
                          fluidWidth
                          onClick={() => this.openModal(standard.letter)}
                          disabled={
                            index > 0 &&
                            !this.state.answeredLetters.includes(
                              STANDARDS[index - 1].letter
                            )
                          }
                        >
                          <div className={styles.activityButtonContainer}>
                            <span className={styles.activityButtonLetter}>
                              {standard.letter}
                            </span>
                            <span className={styles.activityButtonWords}>
                              {standard.words}
                            </span>
                          </div>
                        </Button>
                        <div
                          className={styles.activityResponses}
                          key={standard.letter}
                        >
                          <ul>{answersText}</ul>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className={styles.backButtonArea}>
                <Button
                  onClick={this.goBack}
                  variant="success"
                  icon={IconArrowOpenStart}
                />
              </div>
              <div className={styles.forwardButtonArea}>
                <Button
                  onClick={this.onSubmit}
                  variant="success"
                  icon={IconArrowOpenEnd}
                  disabled={this.state.answeredLetters.length < 6}
                />
              </div>
            </>
          )}
        </div>

        <RnPActivityModal
          key={this.state.modalContentLetter}
          isOpen={this.state.modalOpen}
          handleModalClose={this.handleModalClose}
          handleSubmit={this.handleModalSubmit}
          header={standardToUse.words}
          questionsToShow={standardToUse.questions}
        />
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, shouldNavigate = false) {
      dispatch(setCurrentStep(stepId));
      if (shouldNavigate) {
        navigate("/modules/normalcy/rnp-unsure");
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(RnPActivity);
